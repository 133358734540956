/* jQuery noConflict */

$ = jQuery.noConflict();

var $log = function(msg) {
        console.log(msg);
    },
    App = {
        init: function() {

            //this.backTop();

            this.sliders();
            this.scripts();
            this.popups();

        },
        scripts: function() {

            // ### Wrap tables ###
            $(".main-content table").wrap("<div class='table-wrap'></div>");

            // ### Accordion ###
            $(".news-article .trigger-link").click(function(e) {
                event.preventDefault();
                $(".news-article.opened").not($(this).parents('.news-article')).toggleClass("opened");
                $(this).parents('.news-article').toggleClass("opened");
                setTimeout(function() {
                    $newsList.isotope();
                }, 550);

            });

            //Animations
            if ($('.home-blocks').length) {
                var homeBlocks = new Waypoint.Inview({
                    element: $('.home-blocks .content-block'),
                    entered: function(direction) {
                        this.element.addClass('animated fadeInUp');
                    }
                })
            }

            if ($('.main-content .content-title').length) {
                var contentTitle = new Waypoint.Inview({
                    element: $('.main-content .content-title'),
                    entered: function(direction) {
                        this.element.addClass('animated');
                    }
                })
            }

            if ($(window).width() > 768 && (!('ontouchstart' in window) || !(navigator.MaxTouchPoints > 0) || !(navigator.msMaxTouchPoints > 0))) {
                App.skrollr = skrollr.init();
            }

            $('.close-modal').click(function(e) {
                e.preventDefault();
                e.stopPropagation();
                App._hideOverlay();
            });

        },

        _showOverlay: function(name) {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                close = $('.close', overlay),
                tint = $('.tint'),
                modal = $('.modal');

            body.addClass('noscroll');
            overlay.show();
            tint.addClass('fadeIn');
            overlay.attr('class', 'modal-overlay').addClass(name);
            $('.modal-content', overlay).hide();
            $('#' + name).show(function() {

                $(this).trigger("modal.shown");

            });
            modal.addClass('fadeInDownBig');

        },

        _hideOverlay: function() {
            var body = $('body'),
                overlay = $('.modal-overlay'),
                close = $('.close', overlay),
                tint = $('.tint'),
                modal = $('.modal');

            modal.removeClass('fadeInDownBig').addClass('fadeOutUp');
            tint.removeClass('fadeIn').addClass('fadeOut');
            $('.modal .modal-content').hide();
            $(".modal-trigger.modal-active").removeClass("modal-active");

            setTimeout(function() {
                modal.removeClass('fadeInDownBig fadeOutUp');
                tint.removeClass('fadeOut fadeIn');
                overlay.hide().attr('class', 'modal-overlay');
                body.removeClass('noscroll');
            }, 500);
        },

        popups: function() {

            var body = $('body'),
                overlay = $('.modal-overlay'),
                tint = $('.tint'),
                close = $('.close', overlay),
                modal_triggers = $('.modal-trigger'),
                active_item = false;

            //App._hideOverlay();

            // Modal close button
            $('.close').click(function(e) {
                e.preventDefault();
                e.stopPropagation();
                App._hideOverlay();
            });

            // Attach popups event handlers
            modal_triggers.click(function(e) {

                e.preventDefault();

                var self = $(this),
                    overlay_id = self.data('modal'),
                    img = $('#' + overlay_id + ' .modal-img');

                modal_triggers.filter(".modal-active").removeClass("modal-active");
                self.addClass("modal-active");

                active_item = self;

                App._showOverlay($(this).data('modal'));

            });

            overlay.click(function() {
                App._hideOverlay();
            });

            $('.modal-content', overlay).click(function(e) {
                e.stopPropagation();
            });

            $(window).on("keyup", function(event) {

                if (modal_triggers.filter(".modal-active").length) {
                    switch (event.keyCode) {
                        case 27: //esc
                            App._hideOverlay();
                            break;
                    }
                }

            });

        },

        sliders: function() {

            $(".references-slider .img-slider-wrap").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                fade: false,
                dots: true,
                asNavFor: $(".references-slider .info-slider-wrap"),
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3000
            });

            $(".references-slider .info-slider-wrap").slick({
                variableWidth: false,
                slidesToScroll: 1,
                dots: false,
                fade: true,
                arrows: false,
                infinite: true,
                centerMode: true,
                asNavFor: $(".references-slider .img-slider-wrap"),
            });

        },

        mobMenu: function(menuWrapper) {

            $this = menuWrapper;
            var mobMenu = $('.mob-menu', $this);

            /* prepend menu icon */
            $this.prepend('<p class="menu-button"><span></span></p>');

            /* toggle main menu nav */
            $(".menu-button", $this).on("click", function() {

                $(this).toggleClass("cross");
                menuWrapper.toggleClass("opened");
                $('body').toggleClass("opened");

            });

            $(".page-wrap").on("click", function() {

                if ($('body').hasClass("opened")) {

                    $(".menu-button", $this).removeClass("cross");
                    menuWrapper.removeClass("opened");
                    $('body').removeClass("opened");

                }

            });

            var $window = $(window);

            function checkWidth() {
                var wWidth = $window.width();
                if (wWidth > 1024) {
                    //console.log('suur');
                    //menuWrapper.show(300);
                } else {}
            }

            // Execute on load
            checkWidth();

            $(window).bind('resize', function() {
                wWidth = $(window).width();
                checkWidth();
            });

        },

    };

$(document).ready(function() {

    App.init();
    App.mobMenu($('.push-menu-wrap'));

});